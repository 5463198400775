<template>
  <div onclick="ios()" style="width: 100px; height: 100px;"></div>
</template>

<script>
    export default {
        name: "open-ios",
        data() {
            return {
            }
        },
        mounted() {
            this.ios()
        },
        methods: {
            ios() {
                var _clickTime = new Date().getTime();
                //启动间隔20ms运行的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
                var _count = 0, intHandle;
                intHandle = setInterval(function () {
                    _count++;
                    var elsTime = new Date().getTime() - _clickTime;
                    if (_count >= 100 || elsTime > 5000) {
                        console.log(_count)
                        console.log(elsTime)
                        clearInterval(intHandle);
                        //检查app是否打开
                        if (document.hidden || document.webkitHidden) {
                            // app打开了
                            console.log("---->打开了")
                            // window.location.href = this.commSchemeIosUrl;
                            // window.close();
                        } else {
                            // app没打开
                            console.log("---->没打开")
                            let url = "https://apps.apple.com/cn/app/qabillar/id1580325985";
                            window.location.href = url;
                        }
                    }
                }, 5);
            },
        }
    }
</script>

<style scoped>

</style>